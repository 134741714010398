<template>
  <div>
    <el-menu :default-active="$route.path" class="el-menu-horizontal" mode="horizontal" background-color="transparent" text-color="#000" active-text-color="#409EFF">
      <router-link to="/" class="menu-link" active-class="active-link">
        <el-menu-item index="/">首页</el-menu-item>
      </router-link>
      <router-link to="/contact" class="menu-link" active-class="active-link">
        <el-menu-item index="/contact">联系方式</el-menu-item>
      </router-link>
      <router-link to="/about" class="menu-link" active-class="active-link">
        <el-menu-item index="/about">公司简介</el-menu-item>
      </router-link>
    </el-menu>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style scoped>
.el-menu-horizontal {
  margin-bottom: 20px;
  background-color: transparent !important; /* 确保背景色透明 */
  border-bottom: none; /* 移除底部边框 */
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 水平居中对齐 */
}

.menu-link {
  text-decoration: none; /* 移除默认的下划线 */
  display: inline-block; /* 确保 router-link 是块级元素 */
}

.menu-link .el-menu-item {
  height: 60px; /* 可以根据需要调整高度 */
  line-height: 60px; /* 保持文本垂直居中 */
  padding: 0 20px; /* 调整内边距 */
  border-bottom: 2px solid white !important; /* 设置未激活状态下的下划线为白色 */
}

.active-link .el-menu-item {
  border-bottom: 2px solid #409EFF !important; /* 设置激活状态下的下划线为浅蓝色 */
}

.menu-link:hover .el-menu-item,
.menu-link:focus .el-menu-item {
  border-bottom: 2px solid white !important; /* 设置悬停和聚焦状态下的下划线为白色 */
}
</style>