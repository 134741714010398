<template>
  <div class="bid-page">
    <!-- 顶部横幅 -->
    <div class="banner" :style="{ backgroundImage: 'url(/讯点网络1.jpg)' }">
      <div class="banner-overlay" v-if="showOverlay"></div>
      <div class="banner-content" v-if="showBannerContent">
        <p class="subheading">期待你的到来，终于见面。</p>
        <h1 class="title">欢迎您联系我们</h1>
        <el-button type="primary" class="action-button" @click="showQrCodeDialog = true">点击联系</el-button>
      </div>
    </div>
    <!-- 浮动联系方式按钮 -->
    <div class="floating-contact" @click="showQrCodeDialog = true" id="floatingButton">
      <i class="el-icon-chat-line-square"></i>
      <span>点击联系</span>
    </div>
    <!-- 二维码模态框 -->
    <el-dialog title="添加微信" v-model="showQrCodeDialog" width="30%">
      <img :src="qrCodeSrc" alt="二维码" class="dialog-qrcode">
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showQrCodeDialog = false">取 消</el-button>
          <el-button type="primary" @click="showQrCodeDialog = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'Home',
  setup() {
    const qrCodeSrc = '/二维码.jpg'; // 替换为实际的二维码图片路径
    const showQrCodeDialog = ref(false);
    const showBannerContent = ref(true);
    const showOverlay = ref(true);

    onMounted(() => {
      // 页面加载后2秒钟隐藏顶部横幅内容及遮罩层
      setTimeout(() => {
        showBannerContent.value = false;
        showOverlay.value = false;
      }, 2000);

      const button = document.getElementById('floatingButton');
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      function getRandomPosition() {
        const maxX = windowWidth - button.offsetWidth;
        const maxY = windowHeight - button.offsetHeight;
        const x = Math.floor(Math.random() * maxX);
        const y = Math.floor(Math.random() * maxY);
        return { x, y };
      }

      function moveButton() {
        const { x, y } = getRandomPosition();
        button.style.position = 'fixed';
        button.style.left = `${x}px`;
        button.style.top = `${y}px`;
      }

      setInterval(moveButton, 2000); // 每2秒随机移动一次
    });

    return {
      qrCodeSrc,
      showQrCodeDialog,
      showBannerContent,
      showOverlay
    };
  }
};
</script>

<style scoped>
.bid-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  color: #333333;
  background-color: #e6f7ff;
}

/* 背景图片部分 */
.banner {
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  min-height: 400px;
  position: relative;
  overflow: hidden;
}

.banner-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.subheading {
  font-size: 1.2em;
  margin: 0;
}

.title {
  font-size: 2.5em;
  margin: 10px 0;
}

.action-button {
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #00bfff, #0077cc);
  color: #ffffff;
  border: none;
  transition: all 0.3s ease;
}

.action-button:hover {
  background: linear-gradient(135deg, #0077cc, #00bfff);
}

/* 黑色遮罩层 */
.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* 表单部分 */
.form-container {
  padding: 20px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.section-title {
  font-size: 1.5em;
  margin: 0 0 10px;
  color: #333333;
}

.form-item {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-field {
  width: 100%;
  max-width: 300px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  padding: 10px;
  font-size: 1em;
  background: #f9f9f9;
  color: #333333;
}

.send-code-button {
  font-size: 1em;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #00bfff, #0077cc);
  color: #ffffff;
  border: none;
  transition: all 0.3s ease;
}

.send-code-button:hover {
  background: linear-gradient(135deg, #0077cc, #00bfff);
}

.qrcode-container {
  text-align: center;
  margin-top: 20px;
}

.qrcode-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* 文本内容部分 */
.text-content {
  padding: 20px;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  color: #333333;
}

/* 卡片部分 */
.contact-card, .about-card {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  color: #333333;
}

.card-header {
  font-size: 1.5em;
  color: #333333;
}

.card-content {
  padding: 20px;
  text-align: center;
}

.card-qrcode {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

/* 浮动联系方式按钮 */
.floating-contact {
  position: fixed;
  background: #00bfff;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1000; /* 确保按钮在其他内容之上 */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1em;
  white-space: nowrap;
  width: 100px; /* 添加固定宽度 */
  height: 40px; /* 添加固定高度 */
}

.floating-contact:hover {
  background: #0077cc;
}

.floating-contact i {
  font-size: 1.5em;
  margin-right: 5px;
}

.floating-contact span {
  font-size: 0.8em;
  white-space: nowrap;
}

/* 二维码模态框 */
.dialog-qrcode {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .banner {
    min-height: 300px;
  }

  .form-container {
    padding: 15px;
  }

  .form-item .input-field {
    max-width: 100%;
  }

  .contact-card, .about-card {
    max-width: 100%;
  }

  .floating-contact {
    bottom: 10px;
    right: 10px;
  }

  .floating-contact i {
    font-size: 1.2em;
  }

  .floating-contact span {
    display: none;
  }
}

@media (max-width: 480px) {
  .banner {
    min-height: 200px;
  }

  .form-container {
    padding: 10px;
  }

  .form-item {
    flex-direction: column;
  }

  .form-item .input-field {
    margin-bottom: 10px;
  }

  .qrcode-image {
    max-width: 100%;
  }

  .floating-contact {
    bottom: 10px;
    right: 10px;
  }

  .floating-contact i {
    font-size: 1.2em;
  }

  .floating-contact span {
    display: none;
  }
}
</style>